<template>
  <div class="prize-page">
    <div class="prize-container">
      <template v-if="prizeList.length">
        <div class="prize-card"
          v-for="(item, index) in prizeList" :key="index">
          <div class="prize-sign image-common" v-if="item.tag">{{ item.tag }}</div>
          <div class="prize-image">
            <img :src="item.imageUrl" alt="" />
          </div>
          <p class="prize-title">{{ item.name }}</p>
          <p class="prize-desc">{{ item.desc }}</p>
        </div>
      </template>
      <template v-else>
        <div class="image-empty">
          <img src="
//download-cos.yofish.com/yofish-gongjushiyebu/20230308091644618-exchange-empty.png" alt="" />
          <p class="empty-desc">这里什么都没有</p>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getBlindboxActivityInfoApi } from '@/api/base1';
import { getAppStatus } from '@/utils/app';

export default {
  name: 'DrawPrize',
  data() {
    return {
      prizeList: [
      ],
    };
  },
  mounted() {
    document.title = '奖池';
    this.initData();
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return 'd6d2544a-52df-4192-8003-2d60ee24cda3';
      // return '7fafe1a4-ec10-4a41-b33e-365845d41032';
    },
    activityId() {
      return 9;
    },
  },
  methods: {
    async initData() {
      let res;
      const { cuserId, activityId } = this;
      try {
        res = await getBlindboxActivityInfoApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        // return this.$toast(e?.desc || '网络异常');
      }
      if (res?.code === 1) {
        this.prizeList = res?.results?.prizePool?.prizeList || [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/prize.scss';
</style>
